import { SVGProps, useMemo, useState } from "react";
import { toDateString } from "../../utils/format";
import { events, results } from "./calendars";
import { Button } from "../../components/button/Button";
import { useWindowResize } from "../../hooks/useWindowResize";
import { MapPin } from "../../components/icons/MapPin";
import { Title } from "../../components/typography/Title";
import { Download } from "../../components/icons/Download";
import { Trophy } from "../../components/icons/Trophy";
import { Gallery } from "../../components/icons/Gallery";

const seasons = results.map((x) => x.season).sort();

const IconLink = (props: { value: string; Icon: JSX.Element | string }) => {
  const { value, Icon } = props;
  return value ? (
    <a href={value} target="_blank" rel="noreferrer" className="text-black">
      {Icon}
    </a>
  ) : (
    <>
      <span className="text-grey-300">{Icon}</span>
    </>
  );
};

export const Calendar = () => {
  const { windowSize } = useWindowResize();
  const firstSeason = seasons[0];
  const lastSeason = seasons[seasons.length - 1];
  const [season, setSeason] = useState<number>(new Date().getFullYear());
  const seasonEvents = useMemo(
    () =>
      events
        .filter(
          (x) =>
            (!x.date && season === new Date().getFullYear()) ||
            x.date?.getFullYear() === season,
        )
        .sort((a, b) => {
          return a.date && b.date && a.date < b.date ? -1 : 1;
        }),
    [season],
  );

  const Table = () => {
    return (
      <table className="w-full">
        <thead>
          <tr className="border-b-[4px] border-primary-300">
            <th className="text-20 text-left text-primary-300 p-[10px]">
              Datum
            </th>
            <th className="text-20 text-left text-primary-300 p-[10px]">
              Utrka
            </th>
            <th className="text-20 text-left text-primary-300 p-[10px]">
              Raspis
            </th>
            <th className="text-20 text-left text-primary-300 p-[10px]">
              Prijavljeni
            </th>
            <th className="text-20 text-left text-primary-300 p-[10px]">
              Prijava
            </th>
            <th className="text-20 text-left text-primary-300 p-[10px]">QR</th>
            <th className="text-20 text-left text-primary-300 p-[10px]">
              Rezultati
            </th>
            <th className="text-20 text-left text-primary-300 p-[10px]">
              Galerija
            </th>
          </tr>
        </thead>
        <tbody>
          {seasonEvents.map((x, i) => (
            <tr key={i}>
              <td className="text-18 p-[16px] text-left font-extrabold">
                {x.date ? toDateString(x.date) : "???"}
              </td>
              <td className="text-18 p-[16px] text-left">
                <p className="text-18 flex gap-[8px]">
                  {x.title}{" "}
                  <p className="text-grey-500 cursor-pointer">
                    <a
                      className="flex gap-[2px]"
                      href={x.mapLocation}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <MapPin /> {x.location}
                    </a>
                  </p>{" "}
                </p>
              </td>
              <td className="text-18 p-[16px] text-left">
                <IconLink value={x.raspis} Icon={<Article />} />
              </td>
              <td className="text-18 p-[16px] text-left">
                <IconLink value={x.popis} Icon={<Users />} />
              </td>
              <td className="text-18 p-[16px] text-left">
                <IconLink value={x.prijava} Icon={<Pencil />} />
              </td>
              <td className="text-18 p-[16px] text-left">
                <IconLink value={x.qr} Icon={<QR />} />
              </td>
              <td className="text-18 p-[16px] text-left">
                <IconLink value={x.rezultati} Icon={<Trophy />} />
              </td>
              <td className="text-18 p-[16px] text-left">
                <IconLink value={x.galerija} Icon={<Gallery />} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const Cards = () => {
    return (
      <div className="px-[16px]">
        <p className="text-20 text-left text-primary-300">Utrke</p>
        <hr className="border-b-[4px] border-primary-300 my-[12px]" />
        <div className="divide-y">
          {seasonEvents.map((x, i) => (
            <div className="space-y-[6px] py-[8px]" key={i}>
              <p className="text-18 text-primary-300">
                {x.date ? toDateString(x.date) : "???"}
              </p>
              <p className="text-18 font-bold">{x.title}</p>
              <p className="flex gap-[2px] text-grey-500">
                <MapPin />
                {x.location}
              </p>
              <div className="flex gap-[10px]">
                <IconLink value={x.raspis} Icon="RASPIS" />
                <IconLink value={x.popis} Icon="POPIS" />
                <IconLink value={x.prijava} Icon="PRIJAVA" />
                <IconLink value={x.qr} Icon="QR" />
                <IconLink value={x.rezultati} Icon="REZULTATI" />
                <IconLink value={x.galerija} Icon="GALERIJA" />
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const bodovi = useMemo(() => {
    return results.find((x) => x.season === season)?.link;
  }, [season]);

  return (
    <div className="max-w-[1440px] my-0 mx-auto pt-[80px] pb-[40px] lg:pb-[80px] lg:pt-[120px]">
      <div className="space-y-[24px] px-[16px]">
        <Title content="Kalendar" bolded={true} />
        <div className="flex flex-col lg:flex-row gap-[20px] items-center">
          <a
            href="https://docs.google.com/spreadsheets/d/1eOiiV1sgFQ_ipuQjhBdzOenaqUlmjwrZ90Pp0SiNagk/edit?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <Button title="Pretprijavljeni" className="!w-[200px]" disabled={false} />
          </a>
          {bodovi && (
            <a
              href={bodovi}
              target="_blank"
              rel="noreferrer"
              onClick={(e) => {
                if (!bodovi) e.preventDefault();
              }}
            >
              <Button title="Bodovi" className="!w-[200px]" />
            </a>
          )}
          <a
            href={"/files/sbbl_pravilnik_2025.pdf"}
            download
            className="w-fit flex gap-[4px] text-20 items-center border border-grey-500 rounded px-[10px] py-[4px]"
          >
            <Download /> SBBL PRAVILNIK
          </a>{" "}
        </div>
        <div className="flex justify-center items-center gap-[12px]">
          <LeftArrow
            className={`cursor-pointer ${
              firstSeason < season ? "visible" : "invisible"
            }`}
            onClick={() => setSeason(season - 1)}
          />
          <p className="text-20">{season}</p>
          <RightArrow
            className={`cursor-pointer ${
              lastSeason > season ? "visible" : "invisible"
            }`}
            onClick={() => setSeason(season + 1)}
          />
        </div>
        {windowSize < 1024 ? <Cards /> : <Table />}
      </div>
    </div>
  );
};

const Article = () => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1_10106)"
      >
        <path d="M19 4H5a2 2 0 00-2 2v12a2 2 0 002 2h14a2 2 0 002-2V6a2 2 0 00-2-2zM7 8h10M7 12h10M7 16h10"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_10106">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

const QR = () => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1_18546)"
      >
        <path d="M9 4H5a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V5a1 1 0 00-1-1zM7 17v.01M19 4h-4a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1V5a1 1 0 00-1-1zM7 7v.01M9 14H5a1 1 0 00-1 1v4a1 1 0 001 1h4a1 1 0 001-1v-4a1 1 0 00-1-1zM17 7v.01M14 14h3M20 14v.01M14 14v3M14 20h3M17 17h3M20 17v3"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_18546">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

const Pencil = () => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1_14931)"
      >
        <path d="M14 3v4a1 1 0 001 1h4"></path>
        <path d="M17 21H7a2 2 0 01-2-2V5a2 2 0 012-2h7l5 5v11a2 2 0 01-2 2z"></path>
        <path d="M10 18l5-5a1.414 1.414 0 00-2-2l-5 5v2h2z"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_14931">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

const Users = () => {
  return (
    <svg width="24" height="24" fill="none" viewBox="0 0 24 24">
      <g
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        clipPath="url(#clip0_1_20836)"
      >
        <path d="M9 11a4 4 0 100-8 4 4 0 000 8zM3 21v-2a4 4 0 014-4h4a4 4 0 014 4v2M16 3.13a4 4 0 010 7.75M21 21v-2a4 4 0 00-3-3.85"></path>
      </g>
      <defs>
        <clipPath id="clip0_1_20836">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

const LeftArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <g clipPath="url(#clip0_1_12712)">
        <path
          stroke="#0F172A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M15 6l-6 6 6 6"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_12712">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

const RightArrow = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      {...props}
    >
      <g clipPath="url(#clip0_1_12715)">
        <path
          stroke="#0F172A"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M9 6l6 6-6 6"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_1_12715">
          <path fill="#fff" d="M0 0H24V24H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
};
