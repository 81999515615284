enum TrailType {
  XCO = "XCO",
  XCM = "XCM",
}

interface Trail {
  id: number;
  name: string;
  x: number;
  y: number;
  type: TrailType;
  gpx?: string;
  video?: string;
}

export const trailList: Trail[] = [
  {
    id: 1,
    name: "Put Šarana",
    x: 45.802690074202474,
    y: 17.33444470286794,
    type: TrailType.XCO,
    gpx: "/gpx/putSarana2.gpx",
    video: "https://www.youtube.com/embed/WH4cffrZeyE?si=x2xn1JWTYSS-N1y1",
  },
  {
    id: 2,
    name: "Šuma Striborova",
    x: 45.200546,
    y: 18.003416,
    type: TrailType.XCO,
    gpx: "/gpx/sumaStriborova2.gpx",
    video: "https://www.youtube.com/embed/SvSGk-6a5yY?si=OKfoe5VrggwVu8OT",
  },
  {
    id: 3,
    name: "Javorica",
    x: 45.702963099455985,
    y: 17.697954605362078,
    type: TrailType.XCM,
    gpx: "/gpx/javorica2.gpx",
  },
  {
    id: 4,
    name: "Kopika",
    x: 45.56664305509114,
    y: 18.68584349150096,
    type: TrailType.XCO,
    gpx: "/gpx/kopika2.gpx",
    video: "https://www.youtube.com/embed/vnUxbxpSEjY?si=JK6hA2b9oU9FI5sQ",
  },
  {
    id: 5,
    name: "Lapovac",
    x: 45.47977659834472,
    y: 18.11110475767143,
    type: TrailType.XCO,
    gpx: "/gpx/lapovac2.gpx",
    video: "https://www.youtube.com/embed/mpiz27LXGNM?si=eKh88x6nHBQTlDga",
  },
  {
    id: 6,
    name: "Stara Drava",
    x: 45.775716,
    y: 18.15506,
    type: TrailType.XCO,
    gpx: "/gpx/staraDrava2.gpx",
  },
  {
    id: 7,
    name: "Koral",
    x: 45.533357,
    y: 17.866463,
    type: TrailType.XCO,
    gpx: "/gpx/koral2.gpx",
    video: "https://www.youtube.com/embed/renW0jcEZDg?si=PslE0iMa8U-n-c7h",
  },
  {
    id: 8,
    name: "Banovo Brdo",
    x: 45.797197,
    y: 18.690396,
    type: TrailType.XCO,
    gpx: "/gpx/banovoBrdo.gpx",
    video: "https://www.youtube.com/embed/mYnUe9Bxepc?si=7oVuWa485KI-6_9h",
  },
];

interface Team {
  id: number;
  order: number;
  name: string;
  description: string;
  img: string;
  teamImg: string;
  location: string;
  numberOfMembers: number;
  numberOfClubWins?: number;
  president?: string;
  vicePrecident?: string;
  secretary?: string;
  facebook?: string;
  instagram?: string;
  web?: string;
  traildId: number[];
}

export const teamList: Team[] = [
  {
    id: 1,
    order: 6,
    name: "BK Osijek 2010",
    img: "/images/teams/bk-os.png",
    location: "Osijek",
    description:
      "BK Osijek 2010 je pokretač i nositelj natjecateljskog biciklističkog sporta u gradu Osijeku, član HBS-a, Zajednice športskih udruga grada Osijeka, Športske zajednice Osječko – baranjske županije, suosnivač je Slavonske biciklističke udruge, koja okuplja biciklističke klubove iz čitave Slavonije s ciljem organiziranja i provođenja rekreativne Slavonske brdsko biciklističke lige, čiji je aktivni i zapaženi sudionik." +
      "<br/> U dosadašnjem radu, zabilježili smo mnoge vrijedne rezultate na nacionalnim prvenstvima, KUP-u RH i regionalnoj, rekreativnoj SBBL te međunarodnim utrkama. Također, organizatori smo mnogih biciklijada i utrka, na kojima sudjeluje i po stotinjak rekreativaca i natjecatelja, od kojih je najznačajnija XCO Kopika, koju uspješno organiziramo nekoliko godina za redom." +
      "<br/> Klub broji tridesetak aktivnih članova koji se većinom natječu na brdskim utrkama, ali također imamo i članove koji su redovni na cestovnim utrkama. Uz natjecanja, naši članovi sudjeluju u radu kluba te održavanju staza koje koristimo za treninge.",
    president: "Robert Vargić",
    vicePrecident: "Slavko Koprivnjak",
    secretary: "Ivo Anić",
    numberOfMembers: 30,
    teamImg: "/images/teams/bk-os-team.png",
    facebook: "facebook.com/BKOsijek2010",
    instagram: "https://www.instagram.com/bkosijek2010/",
    web: "https://bkosijek2010.hr/",
    traildId: [4, 8],
  },
  {
    id: 2,
    order: 5,
    name: "BK Našice",
    description:
      "Biciklistički Klub Našice primarno se natječe u brdskom biciklizmu što u Slavonsko brdsko biciklističkoj ligi, tako i na području cijele Hrvatske. <br/> Osim natjecateljske sekcije u klubu djeluje i rekreativna sekcija koja okuplja sve biciklističke entuzijaste. <br/>" +
      "Također klub je aktivan oko organizacija događaja kao što su XCO Lapovac, Antunovska biciklijada, Dječja utrka, BIKE & FUN biciklijada, memorijalni maraton Našice - Vukovar i ostale… ",
    img: "/images/teams/bk-nasice.png",
    location: "Našice",
    numberOfMembers: 40,
    numberOfClubWins: 1,
    president: "Ivan Adžić",
    vicePrecident: "Antun Umiljanović",
    secretary: "Ivan Kruljac",
    teamImg: "/images/teams/bk-nasice-team.png",
    facebook: "https://www.facebook.com/bknasice/",
    instagram: "https://www.instagram.com/bk.nasice/",
    traildId: [5],
  },
  {
    id: 3,
    order: 4,
    name: "BK Maraton Team",
    img: "/images/teams/bk-maraton.png",
    location: "Donji Miholjac",
    description:
      "Biciklistički klub Maraton team iz Donjeg Miholjca primarno se natječe u brdskom biciklizmu kako u Slavonsko brdsko biciklističkoj ligi, tako i na području cijele Hrvatske. Osim brdskog biciklizma, članovi Kluba se bave i cestovnim biciklizmom, te odlaze na Granfondo cestovne utrke diljem Hrvatske. " +
      "Također klub je aktivan oko organizacija događaja kao što su XCO Stara Drava, biciklijade Tour de Slavonija, te biciklijade D.Miholjac-Siklos.",
    numberOfMembers: 15,
    numberOfClubWins: 7,
    president: "Siniša Crnčan",
    vicePrecident: "Ivica Kerep",
    secretary: "Maja Lazić",
    teamImg: "/images/teams/bk-maraton-team.png",
    facebook: "https://www.facebook.com/bkmaratonteam/",
    instagram: "https://www.instagram.com/bkmaratonteam/",
    web: "bkmaratonteam.hr",
    traildId: [6],
  },
  {
    id: 4,
    order: 3,
    name: "BK Lood",
    img: "/images/teams/bk-lood.png",
    location: "Orahovica",
    description:
      "Biciklistički klub LooD osnovan je sa ciljem poticanja, unaprjeđivanja i razvoja biciklističkog sporta, sudjelovanja na biciklističkim natjecanjima i organizaciji istih. Klub je osnovan 23. rujna 2008. godine, a osnovala ga je skupina zaljubljenika u brdski biciklizam iz Orahovice i drugih gradova. Sjedište kluba je u Orahovici, a područje djelovanja kluba je usmjereno na neposrednu okolicu grada Orahovice (planina Papuk). Klub trenutno broji 20-tak članova, ali s obzirom na brzi razvoj biciklističkog sporta vjerujemo da će nam se pridružiti mnogi zaljubljenici u ovaj dinamičan i zahtjevan sport. Od 2016. godine klub je član Hrvatskog biciklističkog saveza. Naš cilj je uređivanje i održavanje biciklističkih staza koje bi se nalazile na području pod upravom Parka Prirode Papuk i na šumskim predjelima koji pripadaju općini Orahovica. Smatramo da bi promocijom i razvojem ovog sporta doprinijeli ukupnom razvoju turizma i posjećenosti gradu Orahovici. Od značajnih rezultata izdvojili bi da smo osvojili SBBL u ukupnom poretku u sezoni 2018 i 2019.",
    numberOfMembers: 20,
    president: "Mario Vida",
    vicePrecident: "Rober Hofman",
    secretary: "Tomislav Katalinić",
    facebook: "www.facebook.com/bklood",
    web: "bklood-orahovica.hr",
    teamImg: "/images/teams/bk-lood-team.png",
    traildId: [7],
  },
  {
    id: 5,
    order: 2,
    name: "BK Festung",
    img: "/images/teams/bk-festung.png",
    teamImg: "/images/teams/bk-festung-team.png",
    location: "Slavonski Brod",
    description:
      " Biciklistički klub iz Slavonskog Broda sa dugom tradicijiom održavanja utrka u SBBL. Klub sa najvećim uzastopnim pobjedama ukupnog poretka u SBBL. Organizator triju utrka: XCO Šuma Striborova, XCO Tvrđava i XCM Petnja.",
    numberOfMembers: 30,
    president: "Silvio Vilagoš",
    facebook: "https://www.facebook.com/bkfestung",
    instagram: "https://www.instagram.com/bk_festung/",
    traildId: [2],
  },
  {
    id: 6,
    order: 1,
    name: "BK Bor",
    img: "/images/teams/bk-bor.png",
    location: "Virovitica",
    description:
      "BK BOR je biciklistički klub iz Virovitice, sve samo ne ozbiljnih sportaša, već biciklista rekreativaca, entuzijasta, ljubitelja dobrih MTB vožnji i utrka, dobrog provoda i druženja. Naši članovi preferiraju XC, ali nemamo ništa protiv ako zalutaju i u druge sfere biciklizma." +
      "Iza Kluba je već 15 godina postojanja, za vrijeme kojih je napravljena genijalna XC staza Put Šarana, osvojene brojne medalje, održano 11 biciklističkih utrka, desetak biciklijada, organizirana tematska druženja i vožnje. U deset godina jedna mala i nepoznata utrka prerasla je u dvodnevni događaj Put Šarana Outdoor Weekend i definitivno je jedna od najatraktivnijih utrka u Slavoniji." +
      "Klub koji se natječe, organizira, održava vlastitu stazu i educira postao je obitelj mnogobrojnim članovima sa i bez bicikala. Ako još ne znate tko smo, samo na utrkama tražite ekipu u zelenom koja zadnja ide kući. 😉",
    president: "Ivan Vrbanić",
    vicePrecident: "Filip Prpić",
    secretary: "Matija Turkalj",
    numberOfMembers: 35,
    teamImg: "/images/teams/bk-bor-team.png",
    facebook: "https://www.facebook.com/bkborvirovitica/ ",
    instagram: "https://www.instagram.com/bk_bor/ ",
    web: "https://www.bk-bor.hr",
    traildId: [1],
  },
  {
    id: 7,
    order: 7,
    name: "BBK Požega",
    img: "/images/teams/bk-pozega.png",
    location: "Požega",
    description:
      "Brdsko biciklistički klub Požega (BBK Požega) osnovan je 20. rujna 2002. godine i od tada je nositelj biciklističkog sporta na području Požeštine. Redovan je član Hrvatskog biciklističkog saveza, Slavonske biciklističke udruge te Požeškog športskog saveza." +
      "Vrlo brzo nakon osnivanja Klub je pokrenuo Otvorenu brdsko biciklističku ligu (OBBL), natjecanje sa 7-10 utrka po sezoni, koje su vožene na različitim stazama u Požegi i okolici (na Požeškoj gori, Papuku, a neke i po dolini). OBBL je imao razrađen sustav bodovanja, podjelu na mušku i žensku kategoriju te simbolične nagrade za pobjednike. Liga je trajala od 2003. do 2008. godine (ukupno 6 izdanja), a najbolji vozači bili su Stjepan Franekić i Dario Danilović." +
      "Dvije glavne utrke OBBL-a bile su MTB utrka Papuk (obično se održavala u proljetnom dijelu sezone) te MTB utrka Požega (najčešće u listopadu). Na te su utrke dolazili brojni brdski biciklisti iz drugih dijelova Slavonije te su one, osim natjecateljskog karaktera, služile kao poligon za upoznavanje s kolegama iz drugih klubova i dragocjenu razmjenu iskustava.",
    president: "Matej Skuzin",
    secretary: "Mihael Rukavina",
    numberOfMembers: 20,
    numberOfClubWins: 12,
    teamImg: "/images/teams/bk-pozega-team.png",
    traildId: [],
  },
  {
    id: 8,
    order: 8,
    name: "BK Slatina",
    img: "/images/teams/bk-slatina.png",
    location: "Slatina",
    description:
      "BK Slatina osnovan je daleke 2002. godine. Kroz sve te godine mijenjali su se ljudi, dolazili, odlazili, vraćali se, ali svima je uvijek bila zajednička gotovo hipnotička ljubav samo prema jednom – biciklizmu i prirodi. Neka nova vremena donijela su i nove izazove. Bez obzira što je riječ rekreacija ta od koje sve polazi, kada se priča o bilo kojem lokalnom klubu kod svakog ponaosob uvijek se u nama krije mali skriveni ratnik koji se želi natjecati, ići brže, više, jače, iskušavajući i probijajući vlastite granice. I možda se baš tu krije tajna zašto baš biciklizam. A mi se u Slatini volimo natjecati i to ne krijemo. Volimo se i družiti, a kad spojite ta dva elementa u jedno dobit ćete oglednog člana Biciklističkog kluba Slatina." +
      "Mi se vozimo i po cestama, ali najviše smo u šumi. Nekad sami, češće zajedno, nama je svejedno, samo nek' se vozi.",
    numberOfMembers: 25,
    president: "Drago Zdelar",
    vicePrecident: "Damir Jukić",
    secretary: "Dario Kruljac",
    teamImg: "/images/teams/bk-slatina-team.png",
    facebook: "https://www.facebook.com/biciklistickiklubslatina",
    instagram: "https://www.instagram.com/biciklistickiklubslatina",
    traildId: [3],
  },
  {
    id: 9,
    order: 9,
    name: "BK Titan",
    img: "/images/teams/bk-titan.png",
    location: "Pakrac",
    description:
      "BK Titan Pakrac osnovan 2005. s dugom tradicijom utrkivanja i organizacije, svake godine nadograđuje svoje vozače, staze i događanja, vodeći brigu pri tome o kategorijama natjecatelja. Za svakoga po nešto, mtb, enduro, downhill, road warrior ili samo netko tko se s uživanjem vozi i obilazi znamenitosti pakračkog kraja. GO Titans!",
    numberOfMembers: 27,
    president: "Biljana Vidrić",
    vicePrecident: "Josip Tessari",
    secretary: "Mladen Sinkić",
    teamImg: "/images/teams/bk-titan-team.png",
    facebook: "https://www.facebook.com/bkTitan",
    instagram: "https://www.instagram.com/bk_titan_pakrac/",
    web: 'https://bk-titan.hr/',
    traildId: []
  },
  {
    id: 10,
    order: 10,
    name: "BK Challenge",
    img: "/images/teams/bk-challenge.png",
    location: "Gradiška",
    description:
      "Biciklistički klub „Challenge“ iz Gradiške je osnovan 2020. godine. U klubu postoji "+
      "natjecateljska selekcija od 14 licenciranih natjecatelja, rekreativna sekcija koja "+
      "svakodnevno raste i okuplja sve veći broj rekreativaca, bez obzira na starosnu "+
      "dob. <br> <br>"+
      "Obje sekcije imaju članove koji voze cestu i MTB. "+
      "Pored primarnog cilja-afirmacije sporta, misija kluba se ogleda kroz promociju "+
      "zdravlja, zdravih navika i rekreativnog života našeg grada, ali i upoznavanje "+
      "prirodnih ljepota i potencijala regije. <br> <br> "+
      "Osvajači Premijer lige BiH, višestruki osvajači Prvenstva RS, naši natjecatelji su "+
      "osvajači nacionalnih prvenstava XCO, XCM u više kategorija. "+
      "Domaćini organizacije nacionalnih prvenstava BiH XCO (2023 i 2024), XCC "+
      "2024, Premijer liga Bosne i Hercegovine 2022 i prvenstvo RS 2021. <br> <br> "+
      "Organizatori gradske rekreativne biciklijade “Dr Borislav Šokčević” koja je u tri "+
      "godine postojanja okupila oko 2000 sudionika starosti od 2.5 do 86 godina.",
    numberOfMembers: 27,
    president: "Slavoljub Bjelajac",
    vicePrecident: "Mihajlo Raca",
    secretary: "Dajana Blagojević",
    teamImg: "/images/teams/bk-challenge-team.png",
    facebook: "https://www.facebook.com/profile.php?id=100067600291800&amp;locale=hr_HR",
    instagram: "https://www.instagram.com/bk.challenge/",
    web: 'https://www.bk-challenge.com/',
    traildId: []
  }
];
